import React from "react";
import { CanvasJSChart } from "canvasjs-react-charts";

export default function BarChart(props) {
  let data = [];
  props.popDomains.rankingEntries.forEach((x) => {
    data.push({
      label: x.domain,
      y: x.rank,
      rankChange: x.rankChange,
      domain: x.domain,
      category: x.category,
    });
  });

  const options = {
    title: {
      text: "Popular domains",
    },
    toolTip: {
      content:
        "rank : {y}, rankChange: {rankChange}, category: {category}",
    },
    data: [
      {
        // Change type to "doughnut", "line", "splineArea", etc.
        type: "column",
        dataPoints: data,
      },
    ],
  };
  return (
    <div>
      <CanvasJSChart
        options={options}
        /* onRef={ref => this.chart = ref} */
      />
      {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
    </div>
  );
}
