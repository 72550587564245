import './App.css';
import React, { useState, useEffect } from "react"
import axios from 'axios';
import Line from "./components/Line";
import Bar from "./components/BarChart";
import AttackChart from "./components/AttackChart";
import { Card, CardBody } from "reactstrap";

function App() {

  const base = "https://workers.srinishaa-prabhakaran.workers.dev"
  const [trafficChange, setTrafficChange] = useState()
  const [popDomains, setPopDomains] = useState()
  const [attackLayer, setAttackLayer] = useState()

  useEffect(() => {
    axios.get(`${base}/traffic-change`)
      .then(res => {
        const traffic = res.data;
        setTrafficChange(traffic);
      })
    axios.get(`${base}/popular-domains`)
      .then(res => {
        const domainData = res.data;
        setPopDomains(domainData);
      })
    axios.get(`${base}/attack-layer3`)
      .then(res => {
        const attackData = res.data;
        setAttackLayer(attackData);
      })
  }, [])

  return (
    <div className="App">
      {trafficChange && attackLayer && popDomains ? <div>
        <Card style={{ "margin": "30px" }}>
          <CardBody>
            <Line trafficChange={trafficChange.data} />
          </CardBody>
        </Card>
        <Card style={{ "margin": "20px" }}>
          <CardBody>
            <Bar popDomains={popDomains} />
          </CardBody>
        </Card>
        <Card style={{ "margin": "20px" }}>
          <CardBody>
            <AttackChart attack={attackLayer.data} />
          </CardBody>
        </Card>
      </div> : null}
    </div>
  );
}

export default App;
