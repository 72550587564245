import { CanvasJSChart } from "canvasjs-react-charts";
import React, { Component } from "react";

export default function LineChart(props) {

	let traffic = []
	let httpTraffic = []
	for(let i=0; i<props.trafficChange.total.timestamps.length; i++){
		traffic.push({
			x: new Date(props.trafficChange.total.timestamps[i]),
			y: props.trafficChange.total.values[i]
		})
		httpTraffic.push({
			x: new Date(props.trafficChange.total.timestamps[i]),
			y: props.trafficChange.http.values[i]
		})
	}

  const options = {
    animationEnabled: true,
    title: {
      text: "Traffic Change",
    },
    axisX: {
      valueFormatString: "D/M/Y",
    },
    axisY: {
      title: "Traffic Change",
    },
    data: [
      {
		showInLegend: true,
		legendText: "total traffic",
        yValueFormatString: "",
        xValueFormatString: "D/M/Y",
        type: "spline",
        dataPoints: traffic,
      },
	  {
		showInLegend: true,
		legendText: "http traffic",
        yValueFormatString: "",
        xValueFormatString: "D/M/Y",
        type: "spline",
        dataPoints: httpTraffic,
      },
    ],
  };
  return (
    <div>
      <CanvasJSChart
        options={options}
        /* onRef={ref => this.chart = ref} */
      />
      {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
    </div>
  );
}
