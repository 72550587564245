import { CanvasJSChart } from "canvasjs-react-charts";
import React from "react";

export default function AttackChart(props) {

	let traffic = []
	for(let i=0; i<props.attack.total.timestamps.length; i++){
		traffic.push({
			x: new Date(props.attack.total.timestamps[i]),
			y: props.attack.total.values[i]
		})
	}
  
    console.log(traffic)
  const options = {
    animationEnabled: true,
    title: {
      text: "Layer 3 DDoS Attack",
    },
    axisX: {
      valueFormatString: "D/M/Y",
    },
    axisY: {
      title: "Layer 3 DDoS Attack percentage",
      valueFormatString: "#.#%"
    },
    data: [
      {
        yValueFormatString: "#.#%",
        xValueFormatString: "D/M/Y",
        type: "spline",
        dataPoints: traffic,
      }
    ],
  };
  return (
    <div>
      <CanvasJSChart
        options={options}
        /* onRef={ref => this.chart = ref} */
      />
      {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
    </div>
  );
}
